import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Link } from "@remix-run/react";

import { useURL } from "~/contexts";
import useImageUtils from "~/hooks/useImageUtils";
import type { ProductSwatch as ProductSwatchType } from "~/lib/app.types";
import { cn } from "~/lib/ui";

import Image from "../../../../components/ui/image";

const ProductSwatch = ({
  swatch,
  className,
  ...props
}: React.HTMLAttributes<HTMLImageElement> & {
  swatch: ProductSwatchType;
}) => {
  const { getImageMediaLink } = useImageUtils();
  const image = swatch.images?.swatch?.images?.[0];
  const name = swatch.name;
  if (!image) {
    return null;
  }
  return (
    <Image
      src={getImageMediaLink(image, {
        params: {
          fmt: "auto",
          sm: "c",
          // webvitals requre double the size of the image to match resolution
          w: "44",
          "resize.edge.length": "44",
          "resize.edge": "w",
          q: "50",
        },
      })}
      alt={`Swatch color ${name}`}
      width={22}
      loading="lazy"
      // eslint-disable-next-line
      fetchPriority="low"
      className={cn("w-[22px] rounded-full transition", className)}
      {...props}
    />
  );
};
ProductSwatch.displayName = "ProductSwatch";

const SwatchList = ({
  className,
  swatchClass,
  max = 6,
  min = 1,
  plus,
  ref,
  ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  max?: 6 | (number & {});
  min?: 1 | (number & {});
  swatchClass?: string;
  /**
   * Renders element with the remaining number of swatches
   * @param number
   * @returns
   */
  plus?: (number: number) => ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const { swatches, active, defaultSwatch, setActive, setDefaultSwatch } =
    useSwatchContext();
  const url = useURL();

  return (
    <div
      ref={ref}
      className={cn(
        "flex w-full justify-center gap-2 pt-3 text-center",
        className,
      )}
      {...props}
    >
      {swatches &&
        swatches.length > min &&
        swatches.slice(0, max).map(swatch => (
          <Link
            key={swatch.name}
            to={url(`/${swatch.variationGroup?.productId}.html`)}
            prefetch="intent"
            title={swatch.name}
            className={cn(
              "flex h-[22px] w-[22px] cursor-pointer overflow-hidden rounded-full ",
              active?.name === swatch.name && "outline outline-accent-11",
              swatchClass,
            )}
            onClick={e => {
              e.preventDefault();
              setActive(swatch);
            }}
          >
            <ProductSwatch
              key={`swatch-key${swatch.name}`}
              swatch={swatch}
              onClick={e => {
                setActive(swatch);
                setDefaultSwatch(swatch);
                props.onClick?.(e);
              }}
              onMouseOver={() => setActive(swatch)}
            />
          </Link>
        ))}
      {swatches && swatches.length > max ? (
        typeof plus === "function" ? (
          plus(swatches.length - max)
        ) : (
          <span className="h-4 pt-0.5">+{swatches.length - max}</span>
        )
      ) : null}
    </div>
  );
};
SwatchList.displayName = "SwatchList";

const SwatchContext = createContext<{
  swatches: ProductSwatchType[];
  active: ProductSwatchType | undefined;
  defaultSwatch: ProductSwatchType | undefined;
  setActive: (swatch?: ProductSwatchType) => void;
  setDefaultSwatch: (swatch?: ProductSwatchType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>(undefined as any);

const useSwatchContext = () => {
  const context = useContext(SwatchContext);
  if (!context) {
    throw new Error("useSwatchContext must be used within a SwatchContext");
  }
  return context;
};

const SwatchesProvider = ({
  swatches,
  children,
  defaultValue,
  onChange,
  value,
  ref,
  ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, "children" | "onChange"> & {
  children: ((swatch: ProductSwatchType) => React.ReactNode) | React.ReactNode;
  swatches: ProductSwatchType[];
  defaultValue?: string;
  value?: string;
  onChange?: (color?: string, swatch?: ProductSwatchType) => void;
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const [defaultSwatch, setDefaultSwatch] = useState<
    ProductSwatchType | undefined
  >(
    defaultValue
      ? swatches.find(swatch => swatch.color?.value === defaultValue)
      : swatches[0],
  );
  const [active, setActive] = useState<ProductSwatchType | undefined>(
    defaultSwatch,
  );
  const _onChange = useCallback(
    (value: ProductSwatchType | undefined) => {
      setActive(value);
      onChange?.(value?.name, value);
    },
    [onChange],
  );
  const [preload, setPreload] = useState<boolean>(false);
  const { getImageMediaLink } = useImageUtils();

  const activeToRender = useMemo(() => {
    return value !== undefined
      ? swatches.find(swatch => swatch.name === value)
      : active;
  }, [value, active, swatches]);

  return (
    <div
      ref={ref}
      onFocus={() => setPreload(true)}
      onMouseOver={() => {
        setPreload(true);
      }}
      {...props}
    >
      <SwatchContext.Provider
        value={{
          swatches,
          active: activeToRender,
          defaultSwatch,
          setActive: _onChange,
          setDefaultSwatch,
        }}
      >
        {active === undefined
          ? null
          : typeof children === "function"
            ? children(active)
            : children}
        {preload &&
          swatches.flatMap(swatch =>
            swatch.images.large?.images.map(img => (
              <link
                key={"img" + img.link + swatch.name}
                rel="preload"
                as="image"
                href={getImageMediaLink(img, {
                  type: "plp",
                  params: {
                    fmt: "auto",
                  },
                })}
              />
            )),
          )}
      </SwatchContext.Provider>
    </div>
  );
};
SwatchesProvider.displayName = "SwatchesProvider";

export { ProductSwatch, SwatchesProvider, SwatchList, useSwatchContext };
