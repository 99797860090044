import { useMemo } from "react";

import useImageUtils from "~/hooks/useImageUtils";
import type { Image as ImageType } from "~/lib/app.types";
import { cn } from "~/lib/ui";
import { useRequestInfo } from "~/lib/utils/request-info";

import Image from "../ui/image";

let _isFirst = false;
export const ProductImage = ({
  image,
  lazy,
  small,
  className,
  type = "plp",
  ref,
  ...props
}: React.ComponentProps<"picture"> & {
  image?: ImageType;
  lazy?: boolean;
  small?: boolean;
  type?: "plp" | "pdp";
}) => {
  const isMobile = useRequestInfo().ismobile;
  const { getImageMediaLink } = useImageUtils();
  const isFirstMobile = useMemo(() => {
    if (isMobile && !_isFirst) {
      _isFirst = true;
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return image ? (
    <picture className={cn("h-full w-full", className)} ref={ref} {...props}>
      {small ? (
        // Small images are used for the product list page
        <source
          srcSet={getImageMediaLink(image, {
            params: {
              sm: "edge",
              "resize.edge": "w",
              q: "85",
              "resize.edge.length": "400",
              fmt: "auto",
            },
          })}
          media="(min-width:767px)"
        />
      ) : (
        <source
          srcSet={getImageMediaLink(image, {
            params: {
              sm: "edge",
              "resize.edge": "w",
              q: "85",
              "resize.edge.length": "300",
              fmt: "auto",
            },
          })}
          media="(min-width:600)"
        />
      )}
      <source
        srcSet={`${getImageMediaLink(image, {
          params: {
            sm: "edge",
            "resize.edge": "w",
            q: "85",
            "resize.edge.length": "700",
            fmt: "auto",
          },
        })}`}
        media="(min-width:1024px)"
      />
      <Image
        src={getImageMediaLink(image, {
          type,
          params: {
            fmt: "auto",
          },
        })}
        height={320}
        width={320}
        alt={image.alt || "image"}
        className={cn(
          "block h-full w-full object-cover object-center",
          className,
        )}
        {...(!isMobile
          ? {
              loading: "eager",
              // eslint-disable-next-line react/no-unknown-property
              fetchPriority: "high",
            }
          : isFirstMobile
            ? {
                loading: "eager",
                // eslint-disable-next-line react/no-unknown-property
                fetchPriority: "high",
              }
            : { loading: "lazy" })}
      />
    </picture>
  ) : null;
};
ProductImage.displayName = "ProductImage";
