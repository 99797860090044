export const Logo = ({ width = "90", height = "15" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 486 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.2997 81C63.6484 81 59.999 79.5318 57.3513 76.5955C54.7037 73.5875 53.3799 69.2188 53.3799 63.4894V39.2109H47.1544V27.5013H53.3799V10.4204L67.441 8.91646V27.5013H81.6094V39.2109H67.441V61.4482C67.441 63.8116 67.763 65.5305 68.407 66.6047C69.1226 67.679 70.3033 68.2161 71.9491 68.2161C73.9527 68.2161 76.171 67.6074 78.604 66.3899L82.1461 77.6697C78.0673 79.8899 73.4518 81 68.2997 81Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9238 73.3727C99.076 78.4575 105.802 81 114.103 81C122.261 81 128.916 78.4934 134.068 73.4801C139.291 68.3952 141.903 61.8422 141.903 53.821C141.903 45.7997 139.291 39.2825 134.068 34.2693C128.916 29.256 122.261 26.7494 114.103 26.7494C105.802 26.7494 99.076 29.2918 93.9238 34.3767C88.7716 39.4616 86.1956 45.943 86.1956 53.821C86.1956 61.7706 88.7716 68.2878 93.9238 73.3727ZM123.656 64.2414C121.08 66.9629 117.896 68.3236 114.103 68.3236C110.096 68.3236 106.804 66.9629 104.228 64.2414C101.724 61.4483 100.471 57.9748 100.471 53.821C100.471 49.6671 101.724 46.2295 104.228 43.508C106.804 40.7149 110.096 39.3183 114.103 39.3183C117.967 39.3183 121.187 40.6791 123.763 43.4005C126.339 46.0504 127.627 49.5239 127.627 53.821C127.627 58.0464 126.304 61.5199 123.656 64.2414Z"
        fill="currentColor"
      />
      <path
        d="M152.929 80.0331V27.5014H166.991V35.1287H167.205C171.356 29.5425 176.615 26.7494 182.984 26.7494H184.057V42.1114C183.055 42.0398 181.803 42.004 180.3 42.004C176.078 42.004 172.787 43.2931 170.425 45.8714C168.135 48.378 166.991 51.6724 166.991 55.7546V80.0331H152.929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.342 53.2838C240.342 54.931 240.234 56.6141 240.02 58.3329H203.203C204.062 61.4841 205.708 63.9549 208.141 65.7454C210.574 67.5358 213.543 68.431 217.05 68.431C222.345 68.431 226.603 66.4615 229.823 62.5225L238.946 71.0093C233.794 77.6697 226.173 81 216.084 81C207.998 81 201.379 78.4934 196.226 73.4801C191.146 68.4668 188.606 61.878 188.606 53.7135C188.606 45.7639 191.074 39.2825 196.012 34.2693C201.021 29.256 207.425 26.7494 215.225 26.7494C222.595 26.7494 228.606 29.256 233.258 34.2693C237.98 39.2109 240.342 45.5491 240.342 53.2838ZM215.118 38.996C212.255 38.996 209.751 39.8196 207.604 41.4669C205.529 43.0425 204.098 45.2984 203.311 48.2348H226.388C224.814 42.0756 221.057 38.996 215.118 38.996Z"
        fill="currentColor"
      />
      <path
        d="M0 73.2652C5.93929 78.4217 13.0235 81 21.2526 81C27.836 81 32.845 79.496 36.2798 76.4881C39.7861 73.4801 41.5393 69.5411 41.5393 64.6711C41.5393 62.5942 41.2172 60.8037 40.5732 59.2997C40.0008 57.7241 39.321 56.435 38.5338 55.4324C37.8183 54.4297 36.5302 53.4271 34.6697 52.4244C32.8092 51.3501 31.1992 50.5623 29.8396 50.061C28.48 49.4881 26.369 48.6645 23.5067 47.5902C18.3546 45.6565 15.7785 43.7944 15.7785 42.004C15.7785 40.7149 16.2794 39.748 17.2812 39.1035C18.283 38.4589 19.4279 38.1366 20.716 38.1366C25.2957 38.1366 29.4818 39.9987 33.2743 43.7228L40.1439 33.3024C34.4908 28.9337 28.1222 26.7494 21.038 26.7494C15.528 26.7494 10.9841 28.1817 7.40622 31.0464C3.82834 33.9112 2.0394 37.6711 2.0394 42.3263C2.0394 46.9814 3.43477 50.5982 6.22552 53.1764C9.08783 55.683 13.0951 57.7958 18.2472 59.5146C21.8967 60.7321 24.3654 61.7347 25.6534 62.5225C27.013 63.2387 27.6928 64.1339 27.6928 65.2082C27.6928 66.4973 27.1204 67.5358 25.9755 68.3236C24.9021 69.1114 23.3636 69.5053 21.36 69.5053C15.9932 69.5053 11.1272 67.5 6.76221 63.4894L0 73.2652Z"
        fill="currentColor"
      />
      <path
        d="M255.676 39.2109V80.0331H269.844V39.2109H281.436V27.5013H269.844V19.5517C269.844 14.825 271.597 12.4616 275.104 12.4616C276.964 12.4616 279.54 13.2494 282.832 14.825L285.73 3.8674C281.937 1.28915 277.429 3.05176e-05 272.206 3.05176e-05C266.91 3.05176e-05 262.831 1.57563 259.969 4.72682C257.107 7.87802 255.676 12.4974 255.676 18.5849V27.5013H250.202V39.2109H255.676Z"
        fill="#8283FF"
      />
      <path
        d="M472.154 81C467.502 81 463.853 79.5318 461.205 76.5955C458.558 73.5875 457.234 69.2188 457.234 63.4894V39.2109H451.008V27.5014H457.234V10.4205L471.295 8.9165V27.5014H485.463V39.2109H471.295V61.4483C471.295 63.8117 471.617 65.5305 472.261 66.6048C472.976 67.6791 474.157 68.2162 475.803 68.2162C477.807 68.2162 480.025 67.6074 482.458 66.3899L486 77.6698C481.921 79.8899 477.306 81 472.154 81Z"
        fill="#8283FF"
      />
      <path
        d="M392.843 80.0332V27.5014H406.905V34.2693H407.119C411.341 29.256 416.923 26.7494 423.864 26.7494C436.529 26.7494 442.862 34.1261 442.862 48.8794V80.0332H428.801V52.4245C428.801 47.6261 427.978 44.26 426.333 42.3263C424.687 40.321 422.146 39.3184 418.712 39.3184C415.062 39.3184 412.164 40.5717 410.017 43.0783C407.942 45.5133 406.905 49.0584 406.905 53.7136V80.0332H392.843Z"
        fill="#8283FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.838 73.3727C338.99 78.4576 345.716 81 354.017 81C362.175 81 368.83 78.4934 373.982 73.4801C379.205 68.3953 381.817 61.8422 381.817 53.821C381.817 45.7998 379.205 39.2826 373.982 34.2693C368.83 29.256 362.175 26.7494 354.017 26.7494C345.716 26.7494 338.99 29.2919 333.838 34.3767C328.686 39.4616 326.11 45.943 326.11 53.821C326.11 61.7706 328.686 68.2878 333.838 73.3727ZM363.57 64.2414C360.994 66.9629 357.81 68.3236 354.017 68.3236C350.01 68.3236 346.718 66.9629 344.142 64.2414C341.638 61.4483 340.385 57.9748 340.385 53.821C340.385 49.6672 341.638 46.2295 344.142 43.508C346.718 40.7149 350.01 39.3184 354.017 39.3184C357.881 39.3184 361.101 40.6791 363.677 43.4006C366.253 46.0505 367.542 49.5239 367.542 53.821C367.542 58.0465 366.218 61.5199 363.57 64.2414Z"
        fill="#8283FF"
      />
      <path
        d="M290.433 80.0332V27.5014H304.495V35.1287H304.709C308.86 29.5425 314.119 26.7494 320.488 26.7494H321.561V42.1115C320.559 42.0399 319.307 42.004 317.804 42.004C313.582 42.004 310.291 43.2932 307.929 45.8714C305.639 48.378 304.495 51.6725 304.495 55.7547V80.0332H290.433Z"
        fill="#8283FF"
      />
    </svg>
  );
};
