import type { ScreensConfig } from "tailwindcss/types/config";

export const SCREENS = {
  sm: "600px",
  md: "828px",
  lg: "984px",
  xl: "1240px",
  "2xl": "1496px",
  "sm-max": { max: "1279px" },
  "sm-only": { min: "768px", max: "1279px" },
  "md-only": { min: "1280px", max: "1919px" },
} satisfies ScreensConfig;
