import type React from "react";

import useImageUtils from "~/hooks/useImageUtils";
import { useRootLayoutData } from "~/root";

import DefaultImage from "./default-image";

interface ImageProps<T extends React.ElementType = "img">
  extends React.ImgHTMLAttributes<HTMLImageElement> {}

const Image: React.FC<ImageProps> = ({ ...props }) => {
  const { getImageLink } = useImageUtils();
  const { IMAGES_HOST } = useRootLayoutData();

  let fullSrc = "";

  if (IMAGES_HOST && props.src) {
    fullSrc = props.src.includes(IMAGES_HOST)
      ? props.src
      : getImageLink(props.src);
  }

  return fullSrc ? (
    <img src={fullSrc} alt={props.alt} {...props} />
  ) : (
    <DefaultImage {...props} />
  );
};

export default Image;
