import { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";

import { StarIcon } from "@heroicons/react/20/solid";

import { isValidationError } from "~/components/forms/validationErrorResponse";
import {
  ProductQuickView,
  ProductQuickViewDialog,
} from "~/components/products/product-quick-view";
import { Button } from "~/components/ui/button";
import { useURL } from "~/contexts";
import { useMediaScreenContext } from "~/contexts/madia-screen";
import type {
  C_ProductInfo,
  Image,
  ProductHitWithSwatches,
  ProductSwatch,
  ProductSwatch as ProductSwatchType,
  VariationGroup,
} from "~/lib/app.types";
import { cn } from "~/lib/ui";
import { toProductUrl, toVariantURL } from "~/lib/utils/url";
import type { ProductDetailsHystoryState } from "~/routes/($locale)+/_product-details+/location-state/product-details.location-state";
import { useBasketActions } from "~/routes/($locale)+/resources+/basket";
import { useFormatPrice } from "~/shop/utils/price";

import { ProductImage } from "../../../../components/product-list/product-image";
import * as ProductSwatches from "./product-swatch";

export const useProductTileQuickView = ({
  product,
  fetcherData,
}: {
  product?: ProductHitWithSwatches;
  fetcherData?: unknown;
}) => {
  const [quickViewProduct, setQuickViewProduct] = useState<
    ProductHitWithSwatches | undefined
  >();
  const [quickViewSwatch, setQuickViewSwatch] = useState<ProductSwatch>();
  const [quickViewFetcherData, setQuickViewFetcherData] = useState<unknown>();

  useEffect(() => {
    if (isValidationError(fetcherData)) {
      setQuickViewProduct(product);
      setQuickViewFetcherData(fetcherData);
    }
  }, [fetcherData, product]);

  function setQuickViewData(data: {
    product: ProductHitWithSwatches;
    swatch: ProductSwatch;
  }) {
    setQuickViewProduct(data.product);
    setQuickViewSwatch(data.swatch);
  }

  function onQuickViewClose() {
    setQuickViewFetcherData(undefined);
    setQuickViewProduct(undefined);
  }

  return {
    setQuickViewProduct,
    onQuickViewClose,
    setQuickViewData,
    quickViewSwatch,
    quickViewProduct,
    quickViewFetcherData,
  };
};

export const useProductTileData = (
  product: ProductHitWithSwatches,
  quickNav?: ProductHitWithSwatches[],
  totalProducts?: number,
) => {
  const swatches = useMemo(() => product.c_swatches || [], [product]);
  const url = useURL();
  const defaultSwatch = useMemo(
    () =>
      swatches.find(s => s.color?.value === product.c_productInfo?.c_color)
        ?.color?.value,
    [swatches, product.c_productInfo?.c_color],
  );
  const getVariantURL = useCallback(
    (variationGroup?: VariationGroup) => {
      if (!variationGroup) return url(toProductUrl(product.c_productInfo));
      return url(toVariantURL(variationGroup));
    },
    [product.c_productInfo, url],
  );

  const getHystoryState = useCallback(
    (
      swatch: ProductSwatchType,
      image?: Image,
    ): ProductDetailsHystoryState | undefined => {
      if (!product.c_productInfo) return undefined;

      return {
        product: product.c_productInfo as SerializeFrom<
          typeof product.c_productInfo
        >,
        swatch,
        image,
        quickNav: quickNav
          ?.map(p => p.c_productInfo)
          .filter(Boolean) as SerializeFrom<C_ProductInfo>[],
        totalProducts,
      };
    },
    [product, quickNav, totalProducts],
  );
  return {
    getHystoryState,
    getVariantURL,
    defaultSwatch,
    swatches,
    url,
  };
};

export const ProductTile = ({
  className,
  product,
  quickNav,
  totalProducts,
  ref,
}: React.HTMLAttributes<HTMLDivElement> & {
  key: string;
  product: ProductHitWithSwatches;
  quickNav?: ProductHitWithSwatches[];
  totalProducts?: number;
  ref?: React.Ref<HTMLDivElement>;
}) => {
  const basket = useBasketActions();
  const screen = useMediaScreenContext();
  const isMobileLike = ["sm", "md"].includes(screen.is);
  const {
    quickViewProduct,
    quickViewFetcherData,
    onQuickViewClose,
    setQuickViewProduct,
  } = useProductTileQuickView({
    product,
    fetcherData: basket.fetcher.data,
  });
  const formatPrice = useFormatPrice();
  const [isHovered, setIsHovered] = useState(false);
  const { getHystoryState, getVariantURL, defaultSwatch, swatches } =
    useProductTileData(product, quickNav, totalProducts);

  const salePrice = product.c_saleprice;
  const standardPrice = product?.c_pricing?.standardprice;

  return (
    <ProductSwatches.SwatchesProvider
      swatches={swatches}
      ref={ref}
      key={product.productId}
      className={cn(
        "flex w-full flex-col items-center justify-center p-6 sm:w-1/2 md:w-1/3 xl:w-1/4",
        className,
      )}
      defaultValue={defaultSwatch}
    >
      {swatch => (
        <>
          <Link
            to={getVariantURL(swatch.variationGroup)}
            className="rounded-xl md:min-h-[310px] lg:min-h-[317px] xl:min-h-[377px]"
            onMouseMove={!isMobileLike ? () => setIsHovered(true) : undefined}
            onMouseLeave={!isMobileLike ? () => setIsHovered(false) : undefined}
            prefetch={isMobileLike ? "viewport" : "intent"}
            state={getHystoryState(
              swatch,
              swatch?.images.large?.images[isHovered ? 1 : 0] || product.image,
            )}
          >
            <ProductImage
              image={
                swatch?.images.large?.images[isHovered ? 1 : 0] || product.image
              }
              type="plp"
              className="min-h-[320px] rounded-xl"
              small
            />
          </Link>
          <div className="relative min-h-[110px] w-full">
            {swatches.length > 0 && (
              <div className="absolute -top-10 h-10 w-full drop-shadow-white">
                <ProductSwatches.SwatchList
                  className="justify-start px-2 text-left"
                  max={5}
                />
              </div>
            )}
            <Link
              to={getVariantURL(swatch.variationGroup)}
              state={getHystoryState(
                swatch,
                swatch?.images.large?.images[isHovered ? 1 : 0] ||
                  product.image,
              )}
            >
              <p className="text-dark-on-light flex w-full pt-2 text-left text-xs font-semibold">
                {product.productName}
              </p>
            </Link>
            <p className="text-dark-on-light pt-2 text-left text-xs font-light">
              {standardPrice ? (
                <>
                  <span className="line-through">
                    {formatPrice(standardPrice)}
                  </span>
                  <span className="pl-1 text-salePrice">
                    {formatPrice(product?.c_pricing?.salesprice)}
                  </span>
                </>
              ) : (
                <>
                  {!salePrice &&
                    formatPrice(
                      swatch?.variationGroup?.c_price?.salesprice ||
                        product.price,
                    )}
                  {salePrice && (
                    <>
                      <span className="line-through">
                        {formatPrice(
                          swatch?.variationGroup?.c_price?.salesprice ||
                            product.price,
                        )}
                      </span>
                      <span className="pl-1 text-salePrice">
                        {formatPrice(salePrice)}
                      </span>
                    </>
                  )}
                </>
              )}
            </p>
            <div className="flex items-center pt-2">
              {[0, 1, 2, 3, 4].map(rating => (
                <StarIcon
                  key={rating}
                  className={cn(
                    Math.round(
                      parseFloat(
                        product?.c_productInfo?.c_bvAverageRating || "1.0",
                      ),
                    ) > rating
                      ? "text-neutralalpha-11"
                      : "text-transparent",
                    "h-[12px] w-[12px] flex-shrink-0 stroke-neutralalpha-11",
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
            <Button
              variant="outline"
              className="mt-2 block justify-start py-0 text-base text-sm font-semibold text-neutral-11"
              onClick={() => {
                setQuickViewProduct(product);
              }}
            >
              Add to bag
            </Button>
          </div>
          <ProductQuickViewDialog
            open={!!quickViewProduct}
            onClose={onQuickViewClose}
          >
            {quickViewProduct && (
              <ProductQuickView
                product={quickViewProduct}
                fetcherData={quickViewFetcherData}
                currentSwatch={swatch}
                onClose={onQuickViewClose}
              />
            )}
          </ProductQuickViewDialog>
        </>
      )}
    </ProductSwatches.SwatchesProvider>
  );
};
ProductTile.displayName = "ProductTile";
