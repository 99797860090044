import type React from "react";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";

import { cn } from "~/lib/ui";

const Accordion = ({
  className,
  children,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Root>) => (
  <AccordionPrimitive.Root
    ref={ref}
    className={cn("w-full rounded-md", className)}
    {...props}
  >
    {children}
  </AccordionPrimitive.Root>
);
Accordion.displayName = AccordionPrimitive.Root.displayName;

const AccordionItem = ({
  className,
  children,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Item>) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("mt-px overflow-hidden first:mt-0 last:border-0", className)}
    {...props}
  >
    {children}
  </AccordionPrimitive.Item>
);
AccordionItem.displayName = AccordionPrimitive.Item.displayName;

const AccordionTrigger = ({
  children,
  className,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Trigger>) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      className={cn(
        "flex h-[45px] w-full flex-1 items-center justify-between border-b-4 border-neutral-3 px-6 py-4 text-lg font-normal text-neutral-11 data-[state=open]:bg-neutral-3 [&_*.chevron]:data-[state=open]:rotate-180",
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
      <ChevronDownIcon
        className="chevron h-5 w-5 text-neutral-11"
        aria-hidden
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = ({
  children,
  className,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Content>) => (
  <AccordionPrimitive.Content
    className={cn("overflow-hidden text-base ", className)}
    {...props}
    ref={ref}
  >
    <div className="px-6 py-4">{children}</div>
  </AccordionPrimitive.Content>
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
