import type { C_ProductInfo, ImageGroup } from "../../lib/app.types";
import { invariant } from "../../lib/utils/utils";

const MEDIA = {
  plp: ["$plp_pwa_desktop$", ""] as const,
  pdp: ["$pdp_desktop_pwa$", ""] as const,
  thumb: ["$pdp_thumb$", ""] as const,
} as const;

export const getImageGroup = (
  imagesGroups: ImageGroup[] | undefined,
  viewType?: "swatch" | "large",
  variationValues?: {
    [key: string]: any;
  },
) => {
  // Find the image group in which every variationAttribute's values contains the corresponding value in variationValues
  return imagesGroups?.find(imagesGroup => {
    if (imagesGroup.viewType !== viewType) return false;

    return imagesGroup.variationAttributes?.every(
      variationAttribute =>
        variationValues &&
        variationAttribute.values?.find(
          ({ value }) => value === variationValues[variationAttribute.id],
        ),
    );
  });
};

export const getVariantImage = (
  product: C_ProductInfo,
  selection?: { [key: string]: string },
  type: "large" | "set" | "swatch" = "large",
) => {
  if (!product.c_imageGroups || product.c_imageGroups.length === 0) return;
  let selectedAttributes = selection;
  if (!selection) {
    invariant(
      product.type?.variant === true,
      "Product is not a variant, provide variant attribute selection",
    );
    selectedAttributes = product.variationValues as Record<string, string>;
  }
  const attributes =
    product.c_imageGroups[0].variationAttributes?.map(att => att.id) || [];

  return product.c_imageGroups.filter(
    v =>
      v.viewType === type &&
      attributes?.every(att =>
        v.variationAttributes
          ?.find(variationAttribute => variationAttribute.id === att)
          ?.values?.find(value => value.value === selectedAttributes?.[att]),
      ),
  )?.[0]?.images;
};
