import { createContext, useContext, useMemo } from "react";
import type { ClassProp, ClassValue, VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { useMatches } from "@remix-run/react";

import { cn, useIsOffScreen } from "~/lib/ui";

import { P } from "../ui/text";

export const MEGANAV_DEFAULT_VARIANT = "dark";

const variant = tv({
  slots: {
    main: "bg-opacity-0 hover:bg-panel-translucent hover:text-inherit sm-max:hover:bg-transparent",
    activated:
      "bg-contrast bg-opacity-100 sm-max:bg-stickyHeader sm-max:text-white",
    focused:
      "focus-within:bg-panel-translucent sm-max:focus-within:bg-transparent",
  },
  variants: {
    text: {
      light: {
        main: "hover:text-inherit focus-within:text-inherit text-white sm-max:hover:text-white sm-max:focus-within:text-white",
        activated: "text-inherit ",
      },
      dark: {
        main: "text-inherit ",
        activated: "text-inherit ",
      },
    },
  },
});

const Context = createContext<{
  handle: NonNullable<MeganavTheme>;
  main: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  activated: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  focused: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  boundryRef: React.RefObject<HTMLElement>;
}>(null as any);

export const useMeganav = () => {
  const ctx = useContext(Context);
  if (!ctx) throw new Error("useMeganav must be used inside a MeganavProvider");
  return ctx;
};

export const Root = ({
  children,
  boundryRef,
}: {
  children: React.ReactNode;
  boundryRef: React.RefObject<HTMLElement>;
}) => {
  const handle = useMenuHandle();
  const { main, activated, focused } = variant({
    text: handle.variant ?? MEGANAV_DEFAULT_VARIANT,
  });
  return (
    <Context.Provider
      value={{
        handle,
        main,
        activated,
        focused,
        boundryRef,
      }}
    >
      {children}
    </Context.Provider>
  );
};
Root.displayName = "Nav.Root";

export const Content = ({
  children,
  ref,
  ...props
}: React.ComponentProps<"div">) => {
  const { main, activated, focused, boundryRef } = useMeganav();
  const isAfterBoundry = useIsOffScreen(boundryRef);

  return (
    <div
      id="nav-content"
      ref={ref}
      className={cn(
        "relative z-10 min-h-nav transition-[background-color] duration-500",
        main(),
        focused(),
        isAfterBoundry && activated(),
      )}
    >
      {children}
    </div>
  );
};
Content.displayName = "Nav.Content";

export const PromoHeading = ({
  children,
  className,
  ref,
}: React.ComponentProps<"div">) => {
  return (
    <div className={cn("top-0 flex h-6", className)} ref={ref}>
      {children}
    </div>
  );
};
PromoHeading.displayName = "Nav.PromoHeading";

export const Header = ({
  children,
  className,
  ref,
}: React.ComponentProps<"div">) => {
  return (
    <header className={cn("top-0 flex h-[68px] w-fit", className)} ref={ref}>
      {children}
    </header>
  );
};
Header.displayName = "Nav.Header";

export const Search = ({
  children,
  className,
  ref,
}: React.ComponentProps<"div">) => {
  return (
    <div className={cn("top-0 w-fit flex-auto", className)} ref={ref}>
      {children}
    </div>
  );
};
Search.displayName = "Nav.Search";

export const MenuContent = ({
  children,
  className,
  ref,
}: React.ComponentProps<"div">) => {
  return (
    <nav
      id="blurrable-content"
      className={cn(
        "left-0 top-0 flex h-[36px] w-auto flex-row items-center",
        className,
      )}
      ref={ref}
    >
      {children}
    </nav>
  );
};
MenuContent.displayName = "Nav.MenuContent";

export const NavBoxVariant = tv({
  base: "absolute sm-max:relative sm-max:pl-0 sm-max:top-0 top-[2rem] z-50 flex",
  variants: {
    position: {
      left: "left-0 justify-start ",
      right: "right-0 justify-end ",
    },
  },
});

export const NavBox = ({
  children,
  position,
  className,
  ref,
}: React.ComponentProps<"div"> & VariantProps<typeof NavBoxVariant>) => {
  return (
    <nav className={NavBoxVariant({ position, className })} ref={ref}>
      {children}
    </nav>
  );
};
NavBox.displayName = "Nav.NavBox";

export const ListItem = ({
  className,
  title,
  children,
  ...props
}: {
  className?: string;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <li className="p-3">
      <P size="fine" className="font-normal leading-tight">
        {title}
      </P>
      <>{children}</>
    </li>
  );
};
ListItem.displayName = "Nav.ListItem";

export const Boundry = ({
  children,
  ref,
  ...props
}: React.ComponentProps<"div">) => {
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};
Boundry.displayName = "Nav.Boundry";

const useMenuHandle = (): NonNullable<MeganavTheme> => {
  const matches = useMatches();
  const match = useMemo(
    () =>
      matches
        .reverse()
        .filter(match => (match as any).handle?.["meganav"])
        .pop(),
    [matches],
  );
  const handle = (match as any)?.handle?.["meganav"];
  if (typeof handle === "function") {
    return handle((match as any).data);
  }
  return handle || ({} satisfies MeganavTheme);
};

type MeganavVariant = VariantProps<typeof variant>["text"];
type MeganavTheme = { variant?: MeganavVariant };
export type MeganavHandle = MeganavTheme | ((data: any) => MeganavTheme);
