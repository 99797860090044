import type { EntryCollection } from "contentful";

import type { SerializeFrom } from "@remix-run/server-runtime";

import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

import {
  type TypeHeroSectionSkeleton,
  isTypeBannerWithLinks,
  isTypeHeroSlot,
  isTypeMediaWithLink,
  isTypeTextBlock,
} from "~/contentful/compiled";

import { ContentBanner } from "./content-banner";
import { HomePageHero, MediaWithLinkContent } from "./home-page-hero";
import { TextContent } from "./text-content";

type ContentType =
  | SerializeFrom<
      EntryCollection<
        TypeHeroSectionSkeleton,
        "WITHOUT_UNRESOLVABLE_LINKS",
        string
      >
    >
  | EntryCollection<
      TypeHeroSectionSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    >;

export const HeroSection = ({
  originalContent,
  preview,
}: {
  originalContent: ContentType;
  preview: boolean;
}) => {
  const liveContentheroSlot = useContentfulLiveUpdates(originalContent);
  const heroSlotContentData = preview ? liveContentheroSlot : originalContent;
  const content = heroSlotContentData?.items[0];

  if (!content) return;
  const heroContent = content.fields.heroContent;
  if (!heroContent) return;

  let size;
  switch (content.fields.size) {
    case "large":
      size = "h-screen";
      break;
    case "small":
      size = "h-96";
      break;
    case "mideum":
      size = "h-128";
      break;
    default:
      break;
  }

  if (isTypeHeroSlot<"WITHOUT_UNRESOLVABLE_LINKS", string>(heroContent)) {
    return <HomePageHero content={heroContent} className={size || ""} />;
  }
  if (isTypeMediaWithLink<"WITHOUT_UNRESOLVABLE_LINKS", string>(heroContent)) {
    return <MediaWithLinkContent content={heroContent} />;
  }
  if (
    isTypeBannerWithLinks<"WITHOUT_UNRESOLVABLE_LINKS", string>(heroContent)
  ) {
    return <ContentBanner content={heroContent} className={size || ""} />;
  }
  if (isTypeTextBlock<"WITHOUT_UNRESOLVABLE_LINKS", string>(heroContent)) {
    return <TextContent content={heroContent} />;
  }

  return null;
};
