import { cn } from "~/lib/ui";

import Image from "../../components/ui/image";

export const getImageMediaLink = (
  imageUrl: string,
  {
    quality,
    params,
  }: {
    quality?: number;
    params: Record<string, string>;
  },
) => {
  const url = new URL(
    imageUrl.startsWith("//") ? "https:" + imageUrl : imageUrl,
  );
  let searchParams = url.searchParams;
  if (quality) url.searchParams.set("qlt", quality.toString());
  if (params)
    searchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });

  return url.origin + url.pathname + "?" + searchParams.toString();
};
const calculateScreenSize = (
  size: number,
  maxWidth: "1/2" | "2/3" | "full",
) => {
  return Math.ceil(
    size * (maxWidth === "1/2" ? 0.5 : maxWidth === "2/3" ? 0.66 : 1),
  );
};
export const ContentfulImage = ({
  imageUrl,
  lazy = true,
  alt,
  small,
  maxWidth,
  className,
  ref,
  ...props
}: React.HTMLAttributes<HTMLPictureElement> & {
  imageUrl: string;
  alt: string;
  lazy?: boolean;
  small?: boolean;
  maxWidth: "1/2" | "2/3" | "full";
  ref?: React.Ref<HTMLElement>;
}) => {
  return imageUrl ? (
    <picture className={cn("h-full w-full", className)} ref={ref} {...props}>
      {small ? (
        // Small images are used for the product list page
        <source
          srcSet={getImageMediaLink(imageUrl, {
            params: {
              q: "100",
              w: calculateScreenSize(600, maxWidth).toString(),
              fm: "webp",
            },
          })}
          media="(min-width:767px)"
        />
      ) : (
        <source
          srcSet={getImageMediaLink(imageUrl, {
            params: {
              q: "100",
              w: calculateScreenSize(600, maxWidth).toString(),
              fm: "webp",
            },
          })}
          media="(max-width:600px)"
        />
      )}
      <source
        srcSet={getImageMediaLink(imageUrl, {
          params: {
            q: "100",
            w: calculateScreenSize(767, maxWidth).toString(),
            fm: "webp",
          },
        })}
        media="(max-width:767px)"
      />
      <source
        srcSet={`${getImageMediaLink(imageUrl, {
          params: {
            q: "85",
            w: calculateScreenSize(1024, maxWidth).toString(),
            fm: "webp",
          },
        })}`}
        media="(max-width:1024px)"
      />
      <source
        srcSet={`${getImageMediaLink(imageUrl, {
          params: {
            q: "85",
            w: calculateScreenSize(1224, maxWidth).toString(),
            fm: "webp",
          },
        })}`}
        media="(max-width:1224px)"
      />
      <source
        srcSet={`${getImageMediaLink(imageUrl, {
          params: {
            q: "85",
            w: calculateScreenSize(1800, maxWidth).toString(),
            fm: "webp",
          },
        })}`}
        media="(min-width:1224px)"
      />
      <source
        srcSet={`${getImageMediaLink(imageUrl, {
          params: {
            q: "100",
            fm: "webp",
          },
        })}`}
        media="(min-width:1800px)"
      />
      <Image
        src={getImageMediaLink(imageUrl, {
          params: {
            q: "100",
            w: calculateScreenSize(800, maxWidth).toString(),
            fm: "webp",
          },
        })}
        alt={alt}
        className={cn(
          "block h-full w-full object-cover object-center",
          className,
        )}
        loading={lazy === false ? "lazy" : "eager"}
      />
    </picture>
  ) : null;
};
ContentfulImage.displayName = "ContentfulImage";
