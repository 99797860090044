import type * as React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Slot } from "@radix-ui/react-slot";

import { cn } from "~/lib/ui";

const buttonVariants = tv({
  base: "inline-flex items-center justify-center rounded-md font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-stone-400 disabled:pointer-events-none disabled:text-neutralalpha-8 disabled:border-neutralalpha-6 dark:focus-visible:ring-stone-800",
  variants: {
    variant: {
      default:
        "border text-neutral-1 bg-neutral-12 border-neutral-12 hover:bg-neutral-11 hover:border-neutralalpha-8 active:bg-neutralalpha-10 disabled:bg-neutralalpha-4",
      outline:
        "border text-neutral-11 bg-neutral-1 border-neutral-3 shadow-sm hover:shadow-none hover:border-neutralalpha-8 active:bg-neutralalpha-3 disabled:bg-neutralalpha-2",
      secondary:
        "bg-stone-100 text-stone-900 shadow-sm hover:bg-stone-100/80 dark:bg-stone-800 dark:text-stone-50 dark:hover:bg-stone-800/80",
      ghost:
        "hover:bg-stone-100 hover:text-stone-900 dark:hover:bg-stone-800 dark:hover:text-stone-50",
      link: "text-stone-900 underline-offset-4 hover:underline dark:text-stone-50",
      linkInline:
        "text-inherit relative m-0 flex h-fit gap-2 p-0 underline-offset-4 hover:underline dark:text-stone-50",
      loading:
        "border !flex text-neutral-1 !bg-neutralalpha-10 border-neutralalpha-8 pointer-events-none disabled:text-neutral-1",
    },
    size: {
      "1": "h-5 rounded-1-max px-2 text-xs",
      "2": "h-6 rounded-2-max px-3 text-sm",
      "3": "h-7 rounded-3-max px-4 text-base",
      "4": "h-8 rounded-4-max px-5 text-lg",
      icon: "h-9 w-9",
      inherit: "text-inherit px-3",
      fit: "",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "3",
  },
  compoundVariants: [
    {
      variant: "linkInline",
      className: "m-0 p-0 h-fit",
    },
  ],
});

export interface ButtonProps<T extends React.ElementType = "button">
  extends VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  as?: T;
}

const Button = <T extends React.ElementType = "button">({
  className,
  variant,
  size,
  asChild = false,
  as,
  ...props
}: ButtonProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>) => {
  const Comp = asChild ? Slot : as || "button";
  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    />
  );
};

export { Button, buttonVariants };
