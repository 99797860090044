import type React from "react";
import type { ReactNode } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";

import { Button } from "./button";

export function CarouselControls() {
  const swiper = useSwiper();
  return (
    <div className="absolute top-1/2 z-10 w-full px-6">
      <div className="flex flex-row justify-between gap-3">
        <Button
          variant="outline"
          className="h-10 w-10 rounded-full bg-page-background p-0 text-base text-text-dark-on-light shadow-md active:bg-panel-translucent disabled:border-0 disabled:bg-panel-translucent"
          onClick={() => swiper.slidePrev()}
          disabled={swiper.isBeginning}
        >
          <CaretLeftIcon className="h-5 w-5 origin-center object-none" />
        </Button>
        <Button
          variant="outline"
          className="h-10 w-10 rounded-full bg-page-background p-0 text-base text-text-dark-on-light shadow-md active:bg-panel-translucent disabled:border-0 disabled:bg-panel-translucent"
          onClick={() => swiper.slideNext()}
          disabled={swiper.isEnd}
        >
          <CaretRightIcon className="h-5 w-5 origin-center object-none" />
        </Button>
      </div>
    </div>
  );
}

const Carousel = ({
  className,
  slideClassName,
  children,
  customNavigation,
  useBubblesNavigation,
  ref,
  ...props
}: Omit<React.ComponentProps<typeof Swiper>, "children"> & {
  children: ReactNode[] | ReactNode;
  slideClassName?: string;
  customNavigation?: ReactNode;
  useBubblesNavigation?: boolean;
}) => {
  if (!Array.isArray(children))
    throw new Error("Expecting children to be array!");
  return (
    <Swiper {...props} className={className} ref={ref}>
      {children.length > 1 && customNavigation}
      {useBubblesNavigation && <CarouselControls />}
      {children.length > 1 ? (
        children?.map((child, i) => (
          <SwiperSlide key={i} className={slideClassName}>
            {child}
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide className={slideClassName}>{children}</SwiperSlide>
      )}
    </Swiper>
  );
};
Carousel.displayName = "Carousel";

export { Carousel };
